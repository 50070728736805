.card {
  margin: 10px;
  padding: 10px;
}
.notSelected {
  margin: 10px;
  padding: 10px;
  border-left-style: solid;
  border-color: rgb(0, 0, 150);
}
.longText {
  width: 100%;
}
a {
  text-decoration: none;
  color: inherit;
}
li {
  margin-left: none;
}
ul {
  list-style: none;
  padding: 0;
}
